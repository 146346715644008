body {
  background-color: $sidebar-color;
}

.cloudinary-image {
  width: auto;
  height: auto;
}

.main {
  background-color: white;
  border-right: $border $border-color;
  height: 100%;
}

.site-button {
  &:hover {
    background-color: unset;
    text-decoration: underline;
    background-image: unset !important;
  }
}

img {
  vertical-align: bottom;
  margin: 0;
  padding: 0;
  border: 0;
}

::selection {
  background: $blue-100 !important;
}

.code-toolbar {
  position: relative;
  pre {
    background-color: #f5f6fa !important;
  }
  code {
    border: unset;
  }

  .toolbar-item {
    position: absolute;
    padding: 8px;
    top: 0px;
    right: 0px;
    color: $grey-dk-100;

    .success {
      svg {
        fill: $link-color;
      }
    }
  }
}

.code-example {
  align-text: center;
  .hcti-container {
    margin: auto;
    display: inline-block;
    background-color: transparentize($blue-100, 0.75);
    border: 1px dashed $blue-100;
  }
  background-image: linear-gradient(0deg,rgba(225,228,232,0),rgba(225,228,232,0) 7px,#e1e4e8 ,rgba(225,228,232,0) 8px),linear-gradient(90deg,rgba(225,228,232,0),rgba(225,228,232,0) 7px,#e1e4e8 ,rgba(225,228,232,0) 8px);
  background-position: center center;
  background-size: 15px 15px;
}

.hint {
  svg {
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 4px;
    fill: $blue-000;
  }
  p {
    margin-left: 28px;
    color: $grey-dk-200;
  }
  background: linear-gradient(140deg, rgba(245,246,250,0.30) 0%, rgba(245,246,250,1) 61%);
  border: 1px solid $grey-lt-100;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: .75rem;
  margin-top: .75rem;
  overflow: auto;
  padding: .75rem;
}

.http-method {
  span {
    background-color: $grey-lt-100;
    border-radius: 3px;
    box-sizing: border-box;
    color: black;
    padding: 4px 8px;
    text-transform: uppercase;
  }
  color: $grey-dk-200;
  border-color: none;
  border-left: solid $blue-100 4px;
  background-color: $code-background-color;
  background: linear-gradient(140deg, rgba(245,246,250,0.50) 0%, rgba(245,246,250,1) 61%);
  border-radius: 3px;
  box-sizing: border-box;
  line-height: 1.45;
  margin-bottom: 16px;
  margin-top: 10px;
  padding: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
}

img {
  border: $border;
  border-color: $border-color;
}

.nav-list-link {
  font-weight: 500;
  color: #3b454e;

  &.child {
    font-weight: 400 !important;
  }
}

.nav-list-link.active {
  color: $link-color !important;
}
