//
// Code and syntax highlighting
//
// stylelint-disable selector-no-qualifying-type, declaration-block-semicolon-newline-after,declaration-block-single-line-max-declarations, selector-no-type, selector-max-type

code {
  padding: 0.2em 0.15em;
  font-weight: 400;
  background-color: $code-background-color;
  border: $border $border-color;
  border-radius: $border-radius;
}

//
// Code examples (rendered)
//

.code-example {
  padding: $sp-3;
  margin-bottom: $sp-3;
  overflow: auto;
  border: 1px solid $border-color;
  border-radius: $border-radius;
}
